let comm = {};

comm.isEmpty = function (value) {
    if (value === undefined || value === null || value === 'null' || value === '' || value.length <= 0) {
        return true;
    } else {
        return false;
    }
};

comm.getHost = function () {
    // let arr = location.pathname.split('/');
    // let pathname = arr.find((value) => value != '') || '';
    // if(pathname && pathname != 'static') {
    //     pathname = '/' + pathname;
    // } else {
    //     pathname = '';
    // }
    let pathname = '';
    let protocol = window.location.protocol;
    let baseUrl = protocol + '//' + location.host + pathname;
    console.log('getHost>>>', baseUrl);
    return baseUrl;
};
export default comm;