import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'finishInfo',
    component:() =>import('@/views/home/index')
  },
  {
    path: '/payment',
    name: 'payment',
    component:() =>import('@/views/home/payment-page')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// router.beforeEach((to,from,next)=>{
//    if(to.name === 'login'){
//       Global.clearToken();
//       next();
//    }else if(to.name !== 'login' && comm.isEmpty(Global.getToken())){
//      next({
//        name: 'login'// home page
//      });
//    }else if(to.name !== 'login' && !comm.isEmpty(Global.getToken())){
//      next();
//    }
// })

export default router
