import axios from 'axios'
import comm from 'utils/comm'
import constants from "@/utils/constants";

let global = {};

global.setUserInfo = function(userInfo){
    try{
        localStorage.setItem(constants.STORAGE_KEY_USER_INFO, JSON.stringify(userInfo));
    }catch (e) {
        localStorage.setItem(constants.STORAGE_KEY_USER_INFO, JSON.stringify({}));
    }
};

global.getUserInfo = function() {
    try{
        let userInfo = JSON.parse(localStorage.getItem(constants.STORAGE_KEY_USER_INFO));
        if (userInfo) {
            return userInfo;
        } else {
            return {};
        }
    }catch (e) {
        return {};
    }
};

global.setParams = function(params){
    this.params = params;
}

global.getParamsKey = function(key){
    return this.params[key] || null;
}


global.setToken = function(token){
    if(token){
        localStorage.setItem(constants.STORAGE_KEY_JWT_TOKEN,token)
    }
}

global.getToken = function(){
    return localStorage.getItem(constants.STORAGE_KEY_JWT_TOKEN)
}

global.clearToken = function(){
        localStorage.removeItem(constants.STORAGE_KEY_JWT_TOKEN)
}

global.setBaseUrl = function(url){
    this.baseUrl = url;
}

global.getBaseUrl = function(){
    if (!this.baseUrl) {
        let pathname = location.pathname.split('/');
        console.log('pathname>>>', pathname);
        pathname.splice(pathname.length - 1, 1);
        let str = pathname.join('/');
        this.baseUrl = location.protocol + '//' + location.host + str;
    }
    return this.baseUrl;
}

global.init = function() {
    return new Promise((resolve,reject)=>{
        axios.get(comm.getHost() + `/data/global-config-${process.env.NODE_ENV}.json`).then((res)=>{
            let params = res.data;
            if(params['BASE_URL']){
                this.setBaseUrl(params['BASE_URL'])
            }
            this.setParams(params);
            resolve(true);
        }).catch((err)=>{
            console.error('global init err', err);
            reject(true);
        })
    })
}

export default global;