import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Global from "@/utils/global";
import '@/elementUI'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/utils/rem'
import '@/static/theme/index.scss';
Vue.use(ElementUI);
Vue.config.productionTip = false
Global.init().then(()=>{
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
});


