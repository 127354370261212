// element 按需引入
import Vue from 'vue'

import { Checkbox, CheckboxGroup, MessageBox, Select, Option, OptionGroup, Input, Tree, Dialog, Row, Col, Form, FormItem, Tooltip, Button, Menu, Submenu, MenuItem, MenuItemGroup, Message, Header, Container, Aside, Main, Card, Pagination, Table, TableColumn, Tabs, TabPane, Breadcrumb, BreadcrumbItem,Dropdown,Avatar,DropdownItem,DropdownMenu,Carousel, CarouselItem, Alert,Radio,RadioGroup,RadioButton,DatePicker} from 'element-ui';
Vue.use(Select)
Vue.use(Option)
Vue.use(OptionGroup)
Vue.use(Input)
Vue.use(Tree)
Vue.use(Dialog)
Vue.use(Row)
Vue.use(Col)
Vue.use(Form)
Vue.use(Button)
Vue.use(FormItem)
Vue.use(Tooltip)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItem)
Vue.use(MenuItemGroup)
Vue.use(Header)
Vue.use(Container)
Vue.use(Aside)
Vue.use(Main)
Vue.use(Card)
Vue.use(Pagination)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(CheckboxGroup)
Vue.use(Checkbox)
Vue.use(Dropdown)
Vue.use(Avatar)
Vue.use(DropdownItem)
Vue.use(DropdownMenu)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(RadioButton)
Vue.use(DatePicker)
Vue.prototype.$message = Message
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm